import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
const routes: Routes = [
 {path: "",  redirectTo: '/admin', pathMatch: 'full'},
  { path: "admin", loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
